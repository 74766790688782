import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './PaymentModal.css';

const PaymentModal = ({ isOpen, onClose, amount }) => {
  const [timeLeft, setTimeLeft] = useState(20 * 60);
  const timerRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      // 开始计时器
      timerRef.current = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerRef.current);
            onClose();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      // 动态加载 settings.js
      const script = document.createElement('script');
      script.src = '/settings.js'; // 确保 settings.js 位于 public 目录下
      script.async = true;
      script.onload = () => {
        console.log('settings.js 已加载');
        // 如果 settings.js 提供了全局初始化函数，尝试调用
        if (window.initializePaymentButtons) {
          window.initializePaymentButtons();
        }
      };
      script.onerror = () => {
        console.error('Failed to load settings.js');
      };
      document.body.appendChild(script);

      return () => {
        // 清除计时器和移除脚本
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
        document.body.removeChild(script);
      };
    } else {
      // 清除计时器
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      setTimeLeft(20 * 60); // 重置计时器
    }
  }, [isOpen, onClose]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  if (!isOpen) return null;

  const modalContent = (
    <div className="payment-modal-overlay">
      <div className="payment-modal">
        <button className="close-modal-button" onClick={onClose}>
          &times;
        </button>
        <h2>请选择您的支付方式</h2>
        <div className="payment-info">
          <div className="payment-amount">
            支付金额: <span>{amount} USDT</span>
          </div>
          <div className="payment-timer">
            倒计时: <span>{formatTime(timeLeft)}</span>
          </div>
        </div>
        {/* 确保按钮有 .claim-button 类且没有 onClick 处理器 */}
        <button
          className="payment-button connectButton claim-button"
        >
          付款
        </button>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modalContent, document.body);
};

export default PaymentModal;