// 购物车，处理浮点数计算精度问题
export function roundMoney(value) {
    return Math.round(value * 100) / 100;
  }
  
  // 加法
  export function addMoney(a, b) {
    return roundMoney(a + b);
  }
  
  // 乘法
  export function multiplyMoney(a, b) {
    return roundMoney(a * b);
  }