import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CollapsibleCart from './CollapsibleCart';
import ImageCarousel from './ImageCarousel';
import AdvertisementBanner from './AdvertisementBanner';
import PaymentModal from './PaymentModal';
import { ChevronDown, ShoppingCart } from 'lucide-react';
import './HomePage.css';

function HomePage() {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('全部');
    const [cart, setCart] = useState([]);
    const [isCartVisible, setIsCartVisible] = useState(false);
    const [carouselImages, setCarouselImages] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showAllCategories, setShowAllCategories] = useState(false);
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

    useEffect(() => {
        loadProducts();
        loadCategories();
        loadCarouselImages();
    }, []);

    const loadProducts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/products`);
            const sortedProducts = Array.isArray(response.data) 
                ? response.data.sort((a, b) => a.order - b.order)
                : [];
            setProducts(sortedProducts);
        } catch (error) {
            console.error('Error fetching products:', error);
            setProducts([]);
        }
    };

    const loadCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/categories`);
            setCategories(response.data);
        } catch (error) {
            console.error('Error loading categories:', error);
        }
    };

    const loadCarouselImages = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/carousel-images`);
            setCarouselImages(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
            console.error('Error fetching carousel images:', error);
            setCarouselImages([]);
        }
    };

    const filteredProducts = products.filter(product => 
        (selectedCategory === '全部' || product.category === selectedCategory) &&
        (product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
         product.category.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const addToCart = (product) => {
        setCart(prevCart => {
            const existingItem = prevCart.find(item => item._id === product._id);
            if (existingItem) {
                return prevCart.map(item =>
                    item._id === product._id ? { ...item, quantity: item.quantity + 1 } : item
                );
            }
            return [...prevCart, { ...product, quantity: 1 }];
        });
        setIsCartVisible(true);
    };

    const updateQuantity = (productId, change) => {
        setCart(prevCart => prevCart.map(item =>
            item._id === productId
                ? { ...item, quantity: Math.max(1, item.quantity + change) }
                : item
        ));
    };

    const removeFromCart = (productId) => {
        setCart(prevCart => prevCart.filter(item => item._id !== productId));
    };

    const totalAmount = cart.reduce((total, item) => total + item.price * item.quantity, 0);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setSelectedCategory('全部');
    };

    const toggleAllCategories = () => {
        setShowAllCategories(!showAllCategories);
    };

    const toggleCart = () => {
        setIsCartVisible(!isCartVisible);
    };

    const handleCheckout = () => {
        setIsPaymentModalOpen(true);
        setIsCartVisible(false);
    };

    const handlePaymentSelection = (paymentMethod) => {
        console.log('Selected payment method:', paymentMethod);
        // 这里可以添加处理支付的逻辑
        setIsPaymentModalOpen(false);
    };

    return (
        <div className="homepage">
            <div className="carousel-container">
                {carouselImages.length > 0 && <ImageCarousel images={carouselImages} />}
            </div>
            <div className="ad-banner-container">
                <AdvertisementBanner />
            </div>
            
            <div className="search-container">
                <input
                    type="text"
                    placeholder="搜索商品..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className="search-input"
                />
                <button className="cart-icon-button" onClick={toggleCart}>
                    <ShoppingCart size={24} />
                    {cart.length > 0 && <span className="cart-item-count">{cart.length}</span>}
                </button>
            </div>
            
            <div className="category-nav-container">
                <nav className="category-nav">
                    <ul>
                        <li key="all">
                            <button 
                                onClick={() => setSelectedCategory('全部')}
                                className={selectedCategory === '全部' ? 'active' : ''}
                            >
                                全部
                            </button>
                        </li>
                        {categories.map(category => (
                            <li key={category._id}>
                                <button
                                    onClick={() => setSelectedCategory(category.name)}
                                    className={selectedCategory === category.name ? 'active' : ''}
                                >
                                    {category.name}
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
                <button className="category-expand-btn" onClick={toggleAllCategories}>
                    <ChevronDown size={20} />
                </button>
            </div>
            
            {showAllCategories && (
                <div className="all-categories">
                    <button
                        onClick={() => {
                            setSelectedCategory('全部');
                            setShowAllCategories(false);
                        }}
                        className={selectedCategory === '全部' ? 'active' : ''}
                    >
                        全部
                    </button>
                    {categories.map(category => (
                        <button
                            key={category._id}
                            onClick={() => {
                                setSelectedCategory(category.name);
                                setShowAllCategories(false);
                            }}
                            className={selectedCategory === category.name ? 'active' : ''}
                        >
                            {category.name}
                        </button>
                    ))}
                </div>
            )}
            
            <div className="content-container">
                <div className="products-grid">
                    {filteredProducts.map(product => (
                        <div key={product._id} className="product-card">
                            <img src={`${process.env.REACT_APP_API_URL}${product.image}`} alt={product.name} className="product-image" />
                            <div className="product-info">
                                <h3>{product.name}</h3>
                                <p className="price">{product.price} usdt</p>
                                <button onClick={() => addToCart(product)}>加入购物车</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            
            <CollapsibleCart
                cart={cart}
                updateQuantity={updateQuantity}
                removeFromCart={removeFromCart}
                totalAmount={totalAmount}
                isVisible={isCartVisible}
                setIsVisible={setIsCartVisible}
                onCheckout={handleCheckout}
            />

            {isPaymentModalOpen && (
                <PaymentModal 
                    isOpen={isPaymentModalOpen}
                    onClose={() => setIsPaymentModalOpen(false)}
                    onSelectPayment={handlePaymentSelection}
                    amount={totalAmount}
                />
            )}
        </div>
    );
}

export default HomePage;
